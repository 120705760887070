<!-- =========================================================================================
    File Name: FormValidation.vue
    Description: Form validation (third-party) - Imports page portions
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <b-card-code>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col sm="6" md="3">
              <small-info
                :comStyle="'box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;'"
                roundSize="40"
                icon="PhoneIcon"
                statistic="Mobile"
                :statistic-title="mobile"
                color="danger"
              />
            </b-col>
            <b-col sm="6" md="3">
              <small-info
                :comStyle="'box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;'"
                roundSize="40"
                icon="MapIcon"
                statistic="Area"
                :statistic-title="area"
                color="success"
              />
            </b-col>
            <b-col sm="6" md="3">
              <small-info
                :comStyle="'box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;'"
                roundSize="40"
                icon="UserIcon"
                statistic="Assignt To"
                :statistic-title="assignto"
                color="warning"
              />
            </b-col>
            <b-col sm="6" md="3">
              <small-info
                :comStyle="'box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;'"
                roundSize="40"
                icon="CompassIcon"
                statistic="Assign Date"
                :statistic-title="assigndate.split('T')[0].split('-').reverse().join('/')"
                color="info"
              />
            </b-col>
            <b-col sm="6" md="3">
              <small-info
                :comStyle="'box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;'"
                roundSize="40"
                icon="PhoneInfo"
                statistic="Source"
                :statistic-title="source"
                color="danger"
              />
            </b-col>
            <b-col sm="6" md="3">
              <small-info
                :comStyle="'box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;'"
                roundSize="40"
                icon="MapIcon"
                statistic="City"
                :statistic-title="city"
                color="success"
              />
            </b-col>
            <b-col sm="6" md="3">
              <small-info
                :comStyle="'box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;'"
                roundSize="40"
                icon="ClockIcon"
                statistic="Lead Age"
                :statistic-title="age"
                color="warning"
              />
            </b-col>
            <b-col md="12" class="d-flex justify-content-end">
              <div>
                <b-media-body class="mt-1 d-flex">
                  <b-badge
                    @click="
                      clickWhatsapp(
                        mobile.includes('+91') ? mobile : '+91'.concat(mobile)
                      )
                    "
                    variant="success"
                    class="badge-glow mr-2 cursor-pointer"
                    style="padding: 6px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style="width: 18px; height: 18px"
                      fill="white"
                      class="bi bi-whatsapp"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
                      />
                    </svg>
                  </b-badge>
                  <!-- <div @click="callIVR(mobile.includes('+91') ? mobile : '+91'.concat(mobile))"
                    v-if=" userRole == 'admin' || userRole == 'employee'">

                    <b-badge variant="primary" class="badge-glow mr-2 cursor-pointer" style="padding: 6px">
                      <svg xmlns="http://www.w3.org/2000/svg" style="width: 18px; height: 18px" fill="white"
                        class="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path
                          d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                      </svg>
                    </b-badge>
                  </div>
                  <a v-else :href="`tel:${mobile.includes('+91') ? mobile : '+91'.concat(mobile)
                    }`">
                    <b-badge variant="primary" class="badge-glow mr-2 cursor-pointer" style="padding: 6px">
                      <svg xmlns="http://www.w3.org/2000/svg" style="width: 18px; height: 18px" fill="white"
                        class="bi bi-whatsapp" viewBox="0 0 16 16">
                        <path
                          d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                      </svg>
                    </b-badge>
                  </a> -->
                  <a
                    :href="`sms:${
                      mobile.includes('+91') ? mobile : '+91'.concat(mobile)
                    }`"
                  >
                    <b-badge
                      variant="info"
                      class="badge-glow cursor-pointer"
                      style="padding: 6px"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style="width: 18px; height: 18px"
                        fill="white"
                        class="bi bi-whatsapp"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                        />
                        <path
                          d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"
                        />
                      </svg>
                    </b-badge>
                  </a>
                </b-media-body>
              </div>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label>Lead Name</label>
                <validation-provider #default="{ errors }" rules="required" name="Name">
                  <b-form-input v-model="name" placeholder="Enter Name" disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="selectedFollowUp"
                >
                  <label>Followup Type</label>
                  <v-select
                    v-model="selectedFollowUp"
                    :state="errors.length > 0 ? false : null"
                    placeholder="None"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="followupOption"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <label>Date Time</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Date Time"
                >
                  <b-form-input
                    v-model="datetime"
                    class="form-control"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label>Followup After Days</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|integer"
                  name="Followup After Days"
                >
                  <b-form-input
                    v-model="nextfollowupafterdays"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Days"
                    @input="handleChange($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label>Followup date</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Followup date"
                >
                  <flat-pickr
                    v-model="nextfollowupdate"
                    style="background: transparent"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      dateFormat: 'd/m/Y H:i',
                    }"
                    @input="handlenextfollowupdatechange($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label>Project Name</label>
                <v-select
                  v-model="project"
                  label="projectname"
                  :options="projectOption"
                  :items="projectOption"
                  @input="handelclickProject($event)"
                  placeholder="None"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label>Plot Name</label>
                <v-select
                  v-model="plot"
                  label="plotno"
                  :options="plotOption"
                  placeholder="None"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Priority"
                >
                  <label>Priority</label>
                  <v-select
                    v-model="selectedPriority"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :state="errors.length > 0 ? false : null"
                    label="name"
                    :options="priorityOption"
                    placeholder="None"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label>Remarks</label>
                <b-form-textarea v-model="remarks" rows="1" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label>Lead Stage</label>
                <v-select
                  v-model="selectedLeadStage"
                  label="name"
                  :options="leadStageOption"
                  @input="leadStageHandleClick"
                  placeholder="None"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label>Lead Category</label>
                <v-select
                  v-model="leadcategory"
                  label="name"
                  :options="leadacategoryOption"
                  placeholder="None"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" :style="leadstage == 'Campaign' ? '' : 'display:none'">
              <b-form-group>
                <label>Campaign Name</label>
                <v-select
                  v-model="campaignname"
                  label="name"
                  :options="campaignOptions"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="leadstage == 'Schedule Zoom Demo'" md="6">
              <b-form-group>
                <label>Date Time</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Date And Time"
                >
                  <flat-pickr
                    v-model="dateandtime"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      dateFormat: 'd/m/Y h:i:s K',
                      defaultDate: new Date(),
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="leadstage == 'Schedule Zoom Demo'" md="6">
              <b-form-group label-for="email">
                <label>Email</label>
                <validation-provider #default="{ errors }" rules="required" name="Email">
                  <b-form-tags
                    v-model="zoomemail"
                    input-id="email"
                    :input-attrs="{
                      'aria-describedby': 'tags-validation-help',
                    }"
                    placeholder="Enter Email"
                    remove-on-delete
                    separator=" "
                    invalid-tag-text="Invalid Email"
                  >
                    <!-- The following slots are for b-form-group -->
                    <template #invalid-feedback> You must provide valid Email </template>
                    <template #description>
                      <div id="tags-validation-help">Please Enter Valid Email</div>
                    </template>
                  </b-form-tags>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="leadstage == 'Schedule Zoom Demo'" md="6">
              <b-form-group label-for="number">
                <label>Number</label>
                <validation-provider #default="{ errors }" rules="required" name="Number">
                  <b-form-tags
                    v-model="zoomnumber"
                    input-id="number"
                    :input-attrs="{
                      'aria-describedby': 'tags-validation-help',
                    }"
                    placeholder="Enter Number"
                    remove-on-delete
                    separator=""
                    invalid-tag-text="Invalid Number"
                  >
                    <!-- The following slots are for b-form-group -->
                    <template #invalid-feedback> You must provide valid Number </template>
                    <template #description>
                      <div id="tags-validation-help">Please Enter Valid Number</div>
                    </template>
                  </b-form-tags>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="leadstage == 'MQL'" md="6">
              <b-form-group>
                <label>Who is decision maker ?</label>
                <b-form-input v-model="answer1" placeholder="Enter Answer" />
              </b-form-group>
            </b-col>
            <b-col v-if="leadstage == 'MQL'" md="6">
              <b-form-group>
                <label>Are you buying plot for the first time? </label>
                <v-select v-model="answer2" label="name" :options="questionOptions" />
              </b-form-group>
            </b-col>
            <b-col v-if="leadstage == 'MQL'" md="6">
              <b-form-group>
                <label>How are you planning finance for the purchase? </label>
                <b-form-input v-model="answer3" label="name" placeholder="Enter Answer" />
              </b-form-group>
            </b-col>
            <b-col v-if="leadstage == 'MQL'" md="6">
              <b-form-group>
                <label> Is the purchase for stay or investment? </label>
                <v-select v-model="answer4" label="name" :options="questionOptions" />
              </b-form-group>
            </b-col>
            <b-col v-if="leadstage == 'MQL'" md="6">
              <b-form-group>
                <label> How long have you been looking for a plot? </label>
                <b-form-input v-model="answer5" label="name" placeholder="Enter Answer" />
              </b-form-group>
            </b-col>
            <!-- <b-col v-if="leadstage == 'Site Visit'" md="6">
              <b-form-group>
                <label>No of Visitor</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="No of Visitor"
                >
                  <b-form-input
                    v-model="noofvisitor"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter No of Visitor"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="leadstage == 'Site Visit'" md="6">
              <b-form-group>
                <label>Mode of Travelling</label>
                <v-select
                  v-model="modeoftravelling"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="modeOfTravellingOption"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="leadstage == 'Site Visit'" md="6">
              <b-form-group>
                <label>Start DateTime</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="start datetime"
                >
                  <flat-pickr
                    v-model="startdatetime"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      dateFormat: 'd/m/Y h:i:s K',
                    }"
                    @input="handlestartdate($event, item.name)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="leadstage == 'Site Visit'" md="6">
              <b-form-group>
                <label>End DateTime</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="end datetime"
                >
                  <flat-pickr
                    v-model="enddatetime"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      dateFormat: 'd/m/Y h:i:s K',
                    }"
                    @input="handleenddate($event, item.name)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="leadstage == 'Site Visit'" md="6">
              <b-form-group>
                <label>Start Location</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="start location"
                >
                  <b-form-input
                    v-model="startloaction"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Start Location"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="leadstage == 'Site Visit'" md="6">
              <b-form-group>
                <label>End Location</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="end location"
                >
                  <b-form-input
                    v-model="endloaction"
                    :state="errors.length > 0 ? false : null"
                    placeholder="End Location"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <b-col v-if="leadstage == 'Presentation'" md="6">
              <b-form-group>
                <label>Presented By</label>
                <v-select v-model="presentedby" label="name" :options="userData">
                  <template #option="{ name, profile_image, username, surname }">
                    <b-avatar :src="getprofileImage(profile_image)" />
                    <span class="font-weight-bolder"> {{ name }} {{ surname }}</span
                    ><span>({{ username }})</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col v-if="leadstage == 'Presentation'" md="6">
              <b-form-group>
                <label>Presentation Location</label>
                <v-select
                  v-model="presentationlocation"
                  label="name"
                  :options="prelocationOption"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="leadstage == 'Not Interested'" md="6">
              <b-form-group>
                <label>Reason</label>
                <v-select
                  v-model="notintrestedreason"
                  label="name"
                  :options="notintrestedreasonOption"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="leadstage == 'Lost'" md="6">
              <b-form-group>
                <label>Reason</label>
                <v-select v-model="lostreason" label="name" :options="lostreasonOption" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                variant="primary"
                class="mr-4"
                type="submit"
                @click.prevent="submitForm"
                :disabled="!check"
              >
                Submit
              </b-button>
              <b-button variant="primary" @click="onClickBack">Back </b-button>
            </b-col>
          </b-row>
        </b-form>
        <b-modal
          id="modal-form"
          v-model="popUp"
          size="lg"
          cancel-variant="outline-secondary"
          ok-title="Submit"
          cancel-title="Close"
          title="Add Site Visit"
          hide-footer
        >
          <add-site-visit
            :fromFollowup="true"
            :selectedUserFollowup="test"
            :closePopUpFollowup="closePopUp"
          />
        </b-modal>
      </validation-observer>
    </b-card-code>
    <div>
      <b-card no-body>
        <b-row>
          <b-col class="defaultTable" cols="12">
            <b-table responsive="sm" :fields="fields" :items="followupData" />
          </b-col>
          <b-col cols="12" class="mt-3">
            <show-lead-details class="example" :LeadDataItem="LeadDataItem" />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import Form from "../../components/form/Form.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import flatPickr from "vue-flatpickr-component";
import AddSiteVisit from "../crm/sitevisit/AddSiteVisit.vue";
import showLeadDetails from "../card/card-advance/showLeadDetails.vue";
import "flatpickr/dist/flatpickr.css";
import Mixins from "@/Mixins";
import smallInfo from "@core/components/statistics-cards/smallInfo.vue";

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BCard,
  BTable,
  BFormTags,
  BBadge,
  BLink,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  mixins: [Mixins],
  components: {
    smallInfo,
    BBadge,
    BLink,
    BMediaBody,
    BMedia,
    BMediaAside,

    AddSiteVisit,
    BFormTags,
    flatPickr,
    showLeadDetails,
    PinchScrollZoom,
    Datepicker,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BCard,
    BTable,
    BAvatar,
  },
  data() {
    return {
      index: 0,
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      leadcategory: "",
      campaignOptions: [],
      campaignname: "",
      followupInfo: null,
      followupData: null,
      dropdownData: null,
      dateandtime: moment(new Date()).format("DD/MM/yyyy"),
      presentedOption: [],
      lostreason: "",
      notintrestedreason: "",
      presentedby: [],
      isEdit: false,
      source: "",
      selectedFollowUp: "",
      selectedLeadStage: "",
      followupOption: [
        { name: "Call" },
        { name: "SMS" },
        { name: "Whatsapp" },
        { name: "Email" },
      ],
      prelocationOption: [{ name: "Office" }, { name: "Site" }],
      presentationlocation: "",
      modeOfTravellingOption: [{ name: "Flight" }, { name: "Train" }, { name: "Bus" }],
      selectedPriority: "",
      priorityOption: [{ name: "COLD" }, { name: "HOT" }, { name: "WARM" }],
      notintrestedreasonOption: [{ name: "Not Interested" }],
      lostreasonOption: [{ name: "Lost" }],
      zoomDemo: false,
      userData: [],
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      zoomemail: [],
      zoomnumber: [],
      LeadOptionName: "",
      LeadOptionId: "",
      nextfollowupafterdays: 0,
      leadStageOption: [],
      projectOption: [],
      project: "",
      ProjectName: "",
      plotOption: [],
      plot: "",
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      question5: "",
      Installments: [
        { id: 1, prevHeight: 0 },
        // { id: 2, prevHeight: 0 },
      ],
      check: true,
      questionOptions: [{ name: "Yes" }, { name: "No" }],
      leadstage: null,
      name: "",
      mobile: "",
      city: "",
      LeadDataItem: "",
      area: "",
      age: "",
      userRole: JSON.parse(localStorage.userData).role,
      userLocalData: JSON.parse(localStorage.userData),
      branch: "",
      assigndate: "",
      active: "",
      datetime: moment(new Date()).format("DD/MM/yyyy"),
      remarks: "",
      nextfollowupdate: moment(new Date()).format("DD/MM/yyyy"),
      fields: [
        // { key: "id", label: "Sr. No." },
        { key: "followuptype", label: "Followup Type" },
        { key: "datetime", label: "Date Time" },
        { key: "nextfollowupafterdays", label: "Followup After Days" },
        { key: "status_name", label: "Status" },
        { key: "remarks", label: "Remarks" },
        { key: "enterby", label: "Enter By" },
      ],
      popUp: false,
      test: [],
      assignto: "",
      leadacategoryOption: [],
    };
  },

  async mounted() {
    // this.$eventBus.$emit("onMounted", this.formOptions);
    await this.getLeadStage();
    this.getProject();
    this.edit();
    this.getFollowupInfobyId();
    this.getFollowup();
    this.getLeadCategory();
    if (this.$route.query.id) {
      this.isEdit = true;
    }
  },
  methods: {
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    async getLeadCategory() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/all-leadcategory`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.leadacategoryOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    callIVR(mobile) {
      const options1 = {
        method: "POST",
        url: "https://api-smartflo.tatateleservices.com/v1/click_to_call",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${
            localStorage.IVRData ? JSON.parse(localStorage.IVRData).access_token : ""
          }`,
          "content-type": "application/json",
        },
        data: {
          agent_number: this.userLocalData.mobile,
          destination_number: mobile,
        },
      };

      axios
        .request(options1)
        .then(function (response) {})
        .catch(function (error) {
          console.log(error);
          if (error.toString().includes("401") || error.toString.includes("400")) {
            Mixins.methods.IVRLogin();
          }
        });
    },
    async getUsers() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUsers`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.users = response.data.data;
          this.LeadDataItem &&
            this.LeadDataItem.followupData.map((data) => {
              this.users.map((item) => {
                if (data.enterby == item.id) {
                  data.enterby = item.name;
                }
              });
            });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getProjectForLead() {
      const requestOptionsUsers = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getProject`,
      };

      await axios(requestOptionsUsers).then((response) => {
        this.projects = response.data.data;
        this.LeadDataItem.followupData.map((data) => {
          this.projects.map((item) => {
            if (data.project_id == item.id) {
              data.project_id = item.projectname;
            }
          });
        });
      });
    },
    async getLeadStageForLead() {
      const requestOptionsUsers = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/leadstage`,
      };

      await axios(requestOptionsUsers).then((response) => {
        this.leadOption = response.data.data.data;
        this.LeadDataItem.followupData.map((data) => {
          this.leadOption.map((item) => {
            if (data.status == item.id) {
              data.status = item.name;
            }
          });
        });
        this.$forceUpdate();
      });
    },
    closePopUp() {
      this.popUp = false;
    },

    clickWhatsapp(num) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        let url = "https://wa.me/";
        const all = url + num;
        window.open(all, "_blank");
      } else {
        let url = "https://web.whatsapp.com/send";
        const all = url + "?phone=" + num;
        window.open(all, "_blank");
      }
    },
    async edit() {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getleadById/${this.$route.query.id}`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.followupInfo = response.data.data;
          this.test = response.data.data[0];
          this.mobile = this.followupInfo[0].mobile;
          this.leadcategory = this.followupInfo[0].leadcategory[0];

          this.city = this.followupInfo[0].city;
          this.area = this.followupInfo[0].area;
          this.assigndate = this.followupInfo[0].enterdatetime;
          this.source =
            this.followupInfo[0].subsource.length > 0
              ? this.followupInfo[0].subsource[0].name
              : this.followupInfo[0].leadsource[0].name;

          this.followupInfo.map((item) => {
            let followupLeadData = item.followUpData[item.followUpData.length - 1]
              ? item.followUpData[item.followUpData.length - 1]
              : {};
            this.LeadOptionName = item.status;
            this.leadstage = item.status;
            this.active = item.active;
            this.branch = item.branch;
            this.company = item.company;
            this.name = item.name;
            this.nextfollowupdate = item.leaddate;
            this.remarks = item.remarks;
            this.selectedLeadStage = item.status;
            this.mobile = item.mobile;
            this.city = item.city;
            this.area = item.area;
            this.age = item.leadAge;
            this.branch = item.branch;
            this.active = item.active;
            this.dateandtime = item.dateandtime;
            this.campaign = item.campaign;
            this.answer1 = followupLeadData.answer1;
            this.answer2 = followupLeadData.answer2;
            this.answer3 = followupLeadData.answer3;
            this.answer4 = followupLeadData.answer4;
            this.answer5 = followupLeadData.answer5;

            this.presentationlocation = {
              name: followupLeadData.presentationlocation,
            };
            this.presentedby =
              item.status == "Presentation" &&
              this.getPresentator(followupLeadData.presentedby);
            this.handleAge(this.age);
            this.handleLeadstage(this.leadstage);
            item.status == "Presentation" && this.getUser();

            followupLeadData.emp_email && this.setEmail(followupLeadData.emp_email);
            followupLeadData.emp_mobile && this.setMobile(followupLeadData.emp_mobile);
          });
        })
        .catch((error) => console.log(error, "error"));

      // this.handlenextfollowupdatechange();
    },
    handleLeadstage(e) {
      this.leadStageOption.map((item) => {
        if (item.name == e) {
          this.selectedLeadStage = item;
          this.leadstage = item.name;
        }
      });
    },
    setEmail(email) {
      this.zoomemail = email.split(",");
    },
    setMobile(mobile) {
      this.zoomnumber = mobile.split(",");
    },
    handleAge(ageData) {
      let arr = ageData.split(" ");
      if (arr[0] == "0") {
        if (arr[2] == "0") {
          this.age = arr[5] + " " + arr[6];
        } else {
          let data = ageData.split("0 years,");
          this.age = data[1];
        }
      } else {
        this.age = ageData;
      }
    },
    onClickBack() {
      this.$router.push("/");
    },
    async getPresentator(id) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getusersbyid/${id}`,
      };
      await axios(requestOptions).then((response) => {
        this.presentedby = response.data.data[0];
      });
    },
    handleChange(number) {
      const CurrentDate = new Date();
      CurrentDate.setDate(CurrentDate.getDate() + parseInt(number));
      this.nextfollowupdate = CurrentDate;
    },
    handlenextfollowupdatechange(date) {
      const CurrentDate = new Date();
      const newDate = moment(date, "DD/MM/YYYY").toDate();
      const nextfollowupafterdays = this.datediff(CurrentDate, newDate);
      this.nextfollowupafterdays = nextfollowupafterdays;
      if (nextfollowupafterdays < 1) {
        this.nextfollowupafterdays = 1;
        // this.handleChange(nextfollowupafterdays)
      }
      // this.handleChange(this.nextfollowupafterdays)
      // this.nextfollowupdate = moment(date, "DD/MM/YYYY");
    },
    datediff(first, second) {
      // Take the difference between the dates and divide by milliseconds per day.
      // Round to nearest whole number to deal with DST.
      return Math.round((second - first) / (1000 * 60 * 60 * 24));
    },
    leadStageHandleClick(e) {
      if (e !== null) {
        this.leadstage = e.name;
      } else {
        this.leadstage = null;
      }
      if (this.leadstage == "Presentation") {
        this.getUser();
      } else if (this.leadstage == "Campaign") {
        this.getCampaign();
      } else if (this.leadstage == "Schedule Zoom Demo") {
        this.zoomemail = [this.followupInfo.email];
        this.zoomnumber = [this.followupInfo.mobile];
      }
    },
    async getCampaign(id) {
      if (id !== undefined) {
        this.id = id;
      }

      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getCampaigmaster`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.campaignOptions = response.data.data;
          this.campaignOptions.map((item) => {
            if (item.id == id) {
              this.campaignname = item.name;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getLeadStage() {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptionsUsers = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/leadstage`,
      };

      await axios(requestOptionsUsers)
        .then((response) => {
          const leadOption = response.data.data.data;
          this.leadOption = response.data.data.data;
          const index = 1;
          this.leadStageOption = leadOption;
          leadOption.map((item) => {
            if (item.name == this.LeadOptionName) {
              this.LeadOptionId = item.id;
            }
          });
          // leadOption.map((item) => {
          //   if (item.id + index > this.LeadOptionId) {
          //     this.leadStageOption.push(item);
          //   }
          // });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getFollowupInfobyId() {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: JSON.stringify({
          leadid: this.$route.query.id,
        }),
        url: `${baseApi}/getfollowupbylead`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.followupInfo = response.data;
        })
        .catch((error) => console.log(error, "error"));
      // this.formOptions.inputFields.map((item) => {
      //   if (item.type == 'label' && item.type != 'hidden') {
      //     item.value = this.followupInfo[item.name]
      //   } else {
      //     item.value = ''
      //   }
      // })
    },
    async getAssignto(id) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getusersbyid/${id}`,
      };
      await axios(requestOptions).then((response) => {
        this.assignto = response.data.data[0].name;
      });
    },
    async getFollowup() {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: JSON.stringify({
          leadid: this.$route.query.id,
        }),
        url: `${baseApi}/getleadbyfollowup`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.followupData = response.data.followupData;
          this.assignto =
            response.data.assignto.length > 0 ? response.data.assignto[0].name : "";
          this.LeadDataItem = response.data;
          this.followupData.map((data) => {
            data.status_name = data.status ? data.status.name : "";
            data.enterby = data.enterby_data.length > 0 ? data.enterby_data[0].name : "";

            data.datetime = data.datetime.split("-").reverse().join("-");
            this.leadOption.map((item) => {
              if (item.id == data.status) {
                data.status = item.name;
              }
            });
            this.plot = data.plot;
            this.project = data.project;
            this.handelclickProject(this.project);
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    async handelclickProject(name) {
      if (name && name.id != 0) {
        const accessToken = localStorage.getItem("accessToken");
        const baseApi = process.env.VUE_APP_APIENDPOINT;
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          url: `${baseApi}/plotno`,
          data: {
            project_id: name.id,
          },
        };
        await axios(requestOptions)
          .then((response) => {
            this.plotOption = [];
            this.plotOption = response.data.data;
            this.$forceUpdate();
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    async getProject() {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getProject`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.projectOption = [];
          this.projectOption = response.data.data;
          this.projectOption.push({ id: 0, projectname: "Other" });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getUser() {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        url: `${baseApi}/getEmployee`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.userData = [];
          this.userData = response.data.data;
          this.userData, "data--";
        })
        .catch((error) => console.log(error, "error"));
    },
    async submitForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all the fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          if (this.selectedLeadStage.name == "Site Visit") {
            this.popUp = true;
            return;
          }
          this.check = false;
          const data = {
            active: this.active,
            area: this.area,
            branch: this.branch,
            city: this.city,
            company: this.company,
            email: this.email,
            leadAge: this.age,
            mobile: this.mobile,
            name: this.name,
            nextfollowupafterdays: this.nextfollowupafterdays,
            nextfollowupdate: this.nextfollowupdate,
            remarks: this.remarks,
            status: this.selectedLeadStage.id,
            status_name: this.selectedLeadStage.name,
            followuptype: this.selectedFollowUp.name,
            priority: this.selectedPriority.name,
            leadid: this.$route.query.id,
            emp_email: this.zoomemail,
            emp_mobile: this.zoomnumber,
            project_id: this.project ? this.project.id : "",
            plot_id: this.plot ? this.plot.id : "",
            dateandtime: this.dateandtime,
            answer1: this.answer1 ? this.answer1.name : "",
            answer2: this.answer1 ? this.answer2.name : "",
            answer3: this.answer1 ? this.answer3 : "",
            answer4: this.answer1 ? this.answer4.name : "",
            answer5: this.answer1 ? this.answer5 : "",
            presentedby: this.presentedby ? this.presentedby.id : "",
            leadcategory_id: this.leadcategory ? this.leadcategory.id : "",
            presentationlocation: this.presentationlocation
              ? this.presentationlocation.name
              : "",
          };

          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            url: `${baseApi}/followup`,
            data,
          };
          await axios(requestOptions)
            .then((response) => {
              this.check = true;
              if (this.selectedLeadStage.name !== "Site Visit") {
                this.$swal({
                  title: "Submited",
                  text: response.data.message
                    ? `${response.data.message}`
                    : response.data.success
                    ? `${response.data.success}`
                    : "Update SuccessFully",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
              if (success && this.selectedLeadStage.name == "Sales") {
                const plotData = {
                  project: this.project,
                  projectid: this.project.id,
                  plot: this.plot.name,
                  plotid: this.plot.id,
                  client: this.test,
                };
                localStorage.setItem("plotData", JSON.stringify(plotData));

                if (this.leadcategory && this.leadcategory.name == "Bulk Land") {
                  this.$router.push("/crm/bulklandsales/addbulklandsales");
                } else {
                  this.$router.push("/crm/sales/addsales");
                }
              }
              this.$router.push("/");
            })
            .catch((error) => {
              this.check = true;
              const code = error.toString().includes("409");
              let email, name, message;
              if (code) {
                email = error.response.data.message.original.email
                  ? error.response.data.message.original.email[0]
                  : "";
                name = error.response.data.message.original.name
                  ? error.response.data.message.original.name[0]
                  : "";
                message = email + "  " + name;
              }
              this.$swal({
                title: "Error!",
                text: code ? `${message}` : error,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>
